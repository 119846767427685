import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { closeSnackbar, openSnackbar } from '../store/action-creators';
// material
import Snackbar from '@material-ui/core/Snackbar';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import { Auth, Session } from '../entities/auth';
import { Site } from '../entities/site';
import UtilsService from '../services/utils';


interface AppContainerProps {
  children: any;
  auth: Auth;
  session: Session;
  site: Site;
  private?: boolean;
  color?: string;
  openSignup?: boolean;
  loading?: boolean;
  showOption?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  noPadding?: boolean;
  onRefresh?: void;
  openSnackbar(msg: string): void;
  closeSnackbar(event: any): void;
}

class AppContainer extends Component<AppContainerProps, {}> {

  componentDidMount = () => {
    this.props.openSnackbar("");
    if (this.props.auth && this.props.session) {
      if (!this.props.auth.token && this.props.session.homesViewed > 0) {
        // force the user to login
        // moved code to zaps and saves
        // this.onLogin();
      }
    }
  }

  onLogin = () => {
    window.location.href = UtilsService.getLoginPath();
  }

  render() {
    const maxWidth = this.props.maxWidth !== undefined ? this.props.maxWidth : 'lg';
    const padding = !this.props.maxWidth ? 0 : 32;

    document.body.style.backgroundColor = this.props.color === 'primary' ? '#ECEFF6' : '#FFF';

    return (
      <div style={{
        width: '100%',
        height: '100%',
        minHeight: '100%',
        marginBottom: this.props.noPadding !== undefined && this.props.noPadding ? 0 : 200
      }}
        className="app-container">
        <Header
          onLogin={this.onLogin}
          showOption={this.props.showOption}
          onRefresh={this.props.onRefresh}
          openSignup={this.props.openSignup}
          maxWidth="lg"
        />
        <Hidden mdUp>
          <Container maxWidth={maxWidth} style={{ paddingLeft: 10, paddingRight: 10 }}>
            {this.props.children}
          </Container>
        </Hidden>
        <Hidden smDown>
          <Container maxWidth={maxWidth} style={{ paddingLeft: padding, paddingRight: padding }}>
            {this.props.children}
          </Container>
        </Hidden>
        <Footer />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={(this.props.site.snackbar && this.props.site.snackbarMsg ? true : false)}
          onClose={this.props.closeSnackbar}
          autoHideDuration={2000}
          classes={{
            root: 'message',
          }}
          ContentProps={{
            className: 'message-content'
          }}
          message={<span id="message-id" >{this.props.site.snackbarMsg}</span>}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session,
    site: state.site.site
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ closeSnackbar, openSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContainer);
