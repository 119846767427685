import { Address } from './home';

export interface User {
    id: string;
    authId: string;
    name: string;
    email: string;
    phone: string;
    role: string;
    externalId: string;
    image: string;
    status: string;
    canText: boolean;
    createdDate: number;
}

export interface UserSetting {
    userId: string;
    rate: number;
    rateNotificationActive: boolean;
    createdDate: number;
    updatedDate?: number;
}

export interface UserAddress {
    id: string;
    userId: string;
    addressId: string;
    address?: Address;
    loanAmount: number;
    loanBalance: number;
    loanType: string;
    loanDate: string;
    removeMortgageInsurance: boolean;
    propertyIncrease: number;
    propertyIncreaseActive: boolean;
    propertyCash: number;
    propertyCashActive: boolean;
    propertyEquity: number;
    propertyEquityActive: boolean;
    createdDate: number;
    updatedDate?: number;
}

export const emptyUserAddress: UserAddress = {
    id: '',
    userId: '',
    addressId: '',
    loanAmount: 0,
    loanBalance: 0,
    loanType: '',
    loanDate: '',
    removeMortgageInsurance: false,
    propertyIncrease: 0,
    propertyIncreaseActive: false,
    propertyCash: 0,
    propertyCashActive: false,
    propertyEquity: 0,
    propertyEquityActive: false,
    createdDate: 0
}

export interface UserAddressSetting {
    id: string;
    userId: string;
    addressId: string;
    removeMortgageInsurance: boolean;
    propertyIncrease: number;
    propertyIncreaseActive: boolean;
    propertyCash: number;
    propertyCashActive: boolean;
    propertyEquity: number;
    propertyEquityActive: boolean;
    createdDate: number;
    updatedDate?: number;
}