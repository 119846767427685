import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import { useLocation } from 'react-router-dom'

import { List, ListItem, ListItemText, Grid, Hidden } from '@material-ui/core';
import HasAccess from '../components/auth/HasAccess';
import MobileMenu from '../components/header/MobileMenu';

// state
import state from '../store/index';

interface AuthenticatedContainerProps {
  children: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}))

function ListItemLink(props: any) {
  return <ListItem
    button
    component="a" {...props}
  />;
}

const ProfileContainer: React.FC<AuthenticatedContainerProps> = (props) => {

  // const location = useLocation();

  const location = { pathname: '' };

  const onNavigate = (route: string) => {
    // props.history.push(route);
  }

  return (
    <div className="profile-page">
      <Grid container spacing={5}>
        <Grid item xs={1} md={2}>

          <Hidden mdUp>
            <div>
              <MobileMenu menu={[
                {
                  name: 'Profile',
                  path: '/profile'
                },
                {
                  name: 'Notifications',
                  path: '/notifications'
                }
              ]} onClick={onNavigate} />
            </div>
          </Hidden>
          <Hidden smDown>
            <div className="tabs">
              <a href="/profile" className={location.pathname === '/profile' ? 'selected' : undefined}>
                Profile
              </a>
              <a href="/notifications" className={location.pathname === '/notifications' ? 'selected' : undefined}>
                Notifications
              </a>
              <HasAccess roles={["admin", "superadmin"]}>
                <a href="/organizations" className={location.pathname === '/organizations' ? 'selected' : undefined}>
                  Organizations
                </a>
              </HasAccess>
              <HasAccess roles={["superadmin"]}>
                <a href="/admin/users" className={location.pathname === '/admin/users' ? 'selected' : undefined}>
                  Users
                </a>
              </HasAccess>
            </div>
          </Hidden>
        </Grid>
        <Grid item xs={11} md={10}>
          <div>
            {props.children}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ProfileContainer;
