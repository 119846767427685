import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { isEmpty } from "lodash";

import { Hidden, Container } from "@material-ui/core";

import {
  updateSession,
  deleteAuth,
  clearSignup
} from "../../store/action-creators";
// entities
import {
  Auth as AuthEntity,
  Session,
  Signup as SignupEntity
} from "../../entities/auth";
import { Mortgage } from "../../entities/profile";
// components
import HeaderNav from "./HeaderNav";
import ActionButton from "../buttons/ActionButton";
import Login from "../../pages/auth/components/Login";
import Signup from "../../pages/auth/components/Signup";
import ConfirmCode from "../../pages/auth/components/ConfirmCode";
import ForgotPassword from "../../pages/auth/components/ForgotPassword";
import MobileMenu from './MobileMenu';

const logo = process.env.PUBLIC_URL + "/images/homewise_logo.svg";
const iconProfile = process.env.PUBLIC_URL + "/images/icons/icon_profile.svg";
const iconExit = process.env.PUBLIC_URL + "/images/icons/icon_exit.svg";
const iconCampaign = process.env.PUBLIC_URL + "/images/icons/icon_profile.svg";

const styles = {
  container: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #C9D3E0",
    paddingTop: 10,
    paddingBottom: 10
  },
  headerContainer: {
    justifyContent: "space-between",
    display: "flex",
    overFlow: "hidden",
    margin: "0px auto"
  },
  leftContainer: {
    // display: "flex"
  },
  rightContainer: {
    display: "flex"
  },
  logoContainer: {
    padding: 5,
    width: 200
  },
  logoNameContainer: {
    paddingTop: 17
  },
  logo: {
    cursor: "pointer",
    maxWidth: "100%",
    height: "auto"
  },
  logoMobile: {
    cursor: "pointer",
    maxWidth: 125,
    height: "auto"
  },
  logoName: {
    height: 25,
    cursor: "pointer"
  },
  address: {
    paddingTop: 20,
    paddingRight: 10,
    textDecoration: "underline"
  },
  addressMobile: {
    marginTop: 10,
    marginRight: 10,
    textDecoration: "underline"
  },
  link: {
    display: "flex",
    color: "#627995",
    marginLeft: 20
  },
  icon: {
    marginRight: 10
  },
  button: {
    color: "#00ADCC",
    marginRight: 15,
    marginLeft: 15,
    marginTop: 4
  },
  linkMobile: {
    color: "#00ADCC",
    marginRight: 15
  }
};

interface HeaderProps {
  match?: any;
  history: any;
  signup: SignupEntity;
  auth: AuthEntity;
  session: Session;
  showOption: boolean;
  maxWidth: "xs" | "sm" | "md" | "lg" | "xl" | false;
  openSignup?: boolean;
  clearSignup(): void;
  deleteAuth(): void;
  onRefresh(): void;
  onLogin(): void;
  updateSession(session: Session): void;
}

interface HeaderState {
  showLogin: boolean;
  showSignup: boolean;
  showConfirmCode: boolean;
  showForgotPassword: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {
  state = {
    showLogin: false,
    showSignup: false,
    showConfirmCode: false,
    showForgotPassword: false
  };

  componentDidMount = () => {
    // if (this.props.history.location.pathname === '/' || this.props.history.location.pathname === '/home') {
    //   this.setState({showOption: false})
    // }
  };

  componentWillUpdate = (prevProps: HeaderProps, prevState: HeaderState) => {
    if (prevProps.openSignup !== this.props.openSignup) {
      this.setState({ showSignup: true });
    }
  };

  onLogoClick = () => {
    const newSession = this.props.session;
    newSession.payment = 0;
    newSession.page = "/";
    this.props.updateSession(newSession);
    this.props.history.push("/");
  };

  onNavigate = (route: string) => {
    if (route === "logout") {
      this.handleLogout();
    } else if (route === "sign-in") {
      this.setState({ showLogin: true });
    } else if (route === "sign-up") {
      this.setState({ showSignup: true });
    } else {
      this.props.history.push(route);
    }
  };

  updatePayment = (payment: number) => {
    // update session
    const newSession = this.props.session;
    newSession.payment = payment;
    this.props.updateSession(newSession);
    // goto options page
    if (
      this.props.history.location.pathname === "/options" &&
      this.props.onRefresh !== undefined
    ) {
      this.props.onRefresh();
    } else {
      this.props.history.push("/options");
    }
  };

  handleSignIn = () => {
    // sign in
    this.setState({ showLogin: true });
  };

  handleSignUp = () => {
    // sign up
    this.setState({ showSignup: true });
  };

  handleClose = (route?: string) => {
    if (!route) {
      this.setState({
        showLogin: false,
        showSignup: false,
        showConfirmCode: false
      });
      return;
    }

    if (route === "confirm") {
      this.setState({
        showLogin: false,
        showSignup: false,
        showConfirmCode: true,
        showForgotPassword: false
      });
    } else if (route === "signup") {
      this.setState({
        showLogin: false,
        showSignup: true,
        showConfirmCode: false,
        showForgotPassword: false
      });
    } else if (route === "login") {
      this.setState({
        showLogin: true,
        showSignup: false,
        showConfirmCode: false,
        showForgotPassword: false
      });
    } else if (route === "forgot") {
      this.setState({
        showLogin: false,
        showSignup: false,
        showConfirmCode: false,
        showForgotPassword: true
      });
    } else {
      this.setState({
        showLogin: false,
        showSignup: false,
        showConfirmCode: false,
        showForgotPassword: false
      });
      this.props.history.push(route);
    }
  };

  handleFinishSignup = () => {
    const tmpAuth =
      !isEmpty(this.props.signup) &&
        !isEmpty(this.props.signup.email) &&
        isEmpty(this.props.signup.username)
        ? true
        : false;
    if (tmpAuth) {
      this.setState({
        showSignup: true
      });
    } else {
      this.setState({
        showConfirmCode: true
      });
    }
  };

  handleLogout = async () => {
    this.props.clearSignup();
    this.props.deleteAuth();
    const newSession = this.props.session;
    newSession.address = undefined;
    this.props.updateSession(newSession);
    await Auth.signOut();
    this.props.history.push("/");
  };

  handleAddressClick = () => {
    if (this.props.session.address) {
      this.props.history.push(
        `/estimator/reficenter/${this.props.session.address.id}`
      );
    }
  };

  handleScroll = (key: string) => {
    const elem = document.getElementById(key);
    if (elem) {
      elem.scrollIntoView();
    }
  }

  renderSubTitle = () => {
    return (
      <div style={{ color: "#73808A", fontSize: 10, textAlign: 'center' }}>
        A division of ERS Nationwide Inc
      </div>
    );
  };

  renderIcon = (icon: any) => {
    return <img src={icon} style={styles.icon} alt="" />;
  };

  render() {
    const address = this.props.session.address
      ? this.props.session.address.address
      : "";
    return (
      <div style={styles.container}>
        <Container maxWidth={this.props.maxWidth}>
          <div style={styles.headerContainer}>
            <div style={styles.leftContainer}>
              <div style={styles.logoContainer}>
                <Hidden smDown>
                  <img
                    src={logo}
                    style={styles.logo}
                    onClick={this.onLogoClick}
                  />
                </Hidden>
                <Hidden mdUp>
                  <img
                    src={logo}
                    style={styles.logoMobile}
                    onClick={this.onLogoClick}
                  />
                </Hidden>
                <Hidden smDown>
                  {this.renderSubTitle()}
                </Hidden>
              </div>
            </div>
            <div style={styles.rightContainer}>
              {!isEmpty(this.props.auth) && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Hidden smDown>
                    <div style={styles.link}>
                      <ActionButton
                        textOnly
                        name="Contact Us"
                        onClick={() => this.onNavigate("/contact")}
                      />
                    </div>
                    <div style={styles.link}>
                      {this.renderIcon(iconProfile)}
                      <ActionButton
                        textOnly
                        name="Profile"
                        onClick={() => this.onNavigate("/profile")}
                      />
                    </div>
                    <div style={styles.link}>
                      {this.renderIcon(iconExit)}
                      <ActionButton
                        textOnly
                        name="Logout"
                        onClick={this.handleLogout}
                      />
                    </div>
                  </Hidden>
                  <Hidden mdUp>
                    <div>
                      <MobileMenu menu={[
                        {
                          name: 'Contact Us',
                          path: '/contact'
                        },
                        {
                          name: 'Profile',
                          path: '/profile'
                        },
                        {
                          name: 'Logout',
                          path: 'logout'
                        }
                      ]} onClick={this.onNavigate} />
                    </div>
                  </Hidden>
                </div>
              )}
              {isEmpty(this.props.auth) && isEmpty(this.props.signup) && (
                <div>
                  <Hidden smDown>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={styles.link}>
                        <ActionButton
                          textOnly
                          name="Contact Us"
                          onClick={() => this.onNavigate("/contact")}
                        />
                      </div>
                      <div style={styles.button}>
                        <ActionButton
                          name="Sign Up!"
                          onClick={this.handleSignUp}
                          size="sm"
                        />
                      </div>
                      <div style={styles.link}>
                        <ActionButton
                          name="Sign In"
                          type="secondary"
                          onClick={this.handleSignIn}
                        />
                      </div>
                    </div>
                  </Hidden>
                  <Hidden mdUp>
                    <div>
                      <MobileMenu menu={[
                        {
                          name: 'Contact Us',
                          path: '/contact'
                        },
                        {
                          name: 'Sign Up!',
                          path: 'sign-up'
                        },
                        {
                          name: 'Sign In',
                          path: 'sign-in'
                        }
                      ]} onClick={this.onNavigate} />
                    </div>
                  </Hidden>
                </div>
              )}
              {isEmpty(this.props.auth) && !isEmpty(this.props.signup) && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Hidden smDown>
                    <div style={styles.button}>
                      <ActionButton
                        name="Finish Signup"
                        onClick={this.handleFinishSignup}
                        size="sm"
                      />
                    </div>
                  </Hidden>
                  <Hidden mdUp>
                    <div style={styles.linkMobile}>
                      <ActionButton
                        textOnly
                        name="Finish Signup"
                        onClick={this.handleFinishSignup}
                      />
                    </div>
                  </Hidden>
                </div>
              )}
            </div>

          </div>
          <Hidden mdUp>
            {this.renderSubTitle()}
          </Hidden>
        </Container>
        <Login open={this.state.showLogin} onClose={this.handleClose} />
        <Signup open={this.state.showSignup} onClose={this.handleClose} />
        <ConfirmCode
          open={this.state.showConfirmCode}
          onClose={this.handleClose}
        />
        <ForgotPassword open={this.state.showForgotPassword} onClose={this.handleClose} />
      </div >
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    signup: state.auth.signup,
    auth: state.auth.auth,
    session: state.session.session
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    { updateSession, deleteAuth, clearSignup },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(Header));
