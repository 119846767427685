import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const options = [
  {
    name: 'Contact',
    path: '/contact'
  },
  {
    name: 'Sign In',
    path: '/auth/login'
  },
  {
    name: 'Create Campaign',
    path: '/campaign/new/start'
  }
];

const ITEM_HEIGHT = 48;

interface MobileMenuProps {
  menu: any;
  onClick(route: string): void;
}

const MobileMenu: React.FC<MobileMenuProps> = (props) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {props.menu.map((option) => (
          <MenuItem key={option.name} onClick={() => props.onClick(option.path)}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default MobileMenu;