import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import queryString from 'query-string';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { find } from 'lodash';
import numeral from 'numeral';

// app
import { updateSession, setSignup, openSnackbar } from '../../store/action-creators';
import UtilsService from '../../services/utils';
// containers
import AppContainer from '../../containers/App';
// components
import NavBar from '../../components/site/NavBar';
import Loading from '../../components/site/Loading';
import ResultItem from './components/ResultItem';
import SelectInput, { SelectItem } from '../../components/inputs/SelectInput';
import HomeDetail from './components/HomeDetail';
import Lead from '../../components/site/Lead';
// services
import HomeService from '../../services/home';
// models
import { Session, Auth, Signup } from '../../entities/auth';
import { APIUrl, Lead as LeadEntity, LeadTypes } from '../../entities/site';
import { Home, emptyHome } from '../../entities/home';
import { Option, emptyOption } from '../../entities/option';
// service 
import AuthService from '../../services/auth';
import { mergeClasses } from '@material-ui/styles';
// images
const iconPdc = process.env.PUBLIC_URL + '/images/icons/icon_pdc.svg';
const iconReverseMortgage = process.env.PUBLIC_URL + '/images/icons/icon_reverse_mortgage.svg';


const counties: SelectItem[] = [
  { name: 'Alameda', value: 'Alameda', },
  { name: 'Amador', value: 'Amador' },
  { name: 'Contra Costa', value: 'Contra Costa' },
  { name: 'Marin', value: 'Marin' },
  { name: 'El Dorado', value: 'El Dorado' },
  { name: 'Mendocino', value: 'Mendocino' },
  { name: 'Merced', value: 'Merced' },
  { name: 'Monterey', value: 'Monterey' },
  { name: 'Napa', value: 'Napa' },
  { name: 'Nevada', value: 'Nevada' },
  { name: 'Placer', value: 'Placer' },
  { name: 'Sacramento', value: 'Sacramento' },
  { name: 'San Benito', value: 'San Benito' },
  { name: 'San Francisco', value: 'San Francisco' },
  { name: 'San Joaquin', value: 'San Joaquin' },
  { name: 'San Mateo', value: 'San Mateo' },
  { name: 'Santa Clara', value: 'Santa Clara' },
  { name: 'Santa Cruz', value: 'Santa Cruz' },
  { name: 'Solano', value: 'Solano' },
  { name: 'Sonoma', value: 'Sonoma' },
  { name: 'Stanislaus', value: 'Stanislaus' },
  { name: 'Yolo', value: 'Yolo' },
];


const styles: any = {
  container: {
    paddingTop: 30
  },
  disclaimer: {
    marginTop: 40,
    color: '#778092',
    fontSize: 12,
    textAlign: 'left'
  },
  message: {
    fontSize: 18,
    padding: 40,
    textAlign: 'center',
    color: '#596675'
  },
  leftContainer: {
    borderBottom: '1px solid #E0E0E0'
  },
  maxPurchase: {
    fontSize: 16,
    paddingTop: 10
  },
  number: {
    color: '#009FDB'
  }
}

interface ResultsPageProps {
  match?: any;
  location: any;
  history: any;
  signup: Signup,
  auth: Auth;
  session: Session;
  apiUrls: APIUrl;
  setSignup(username: string, email: string, name: string, phone: string, isReset: boolean): void;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
}

interface ResultsPageState {
  loading: boolean;
  county: string;
  homes: Home[];
  option: Option;
  counties: SelectItem[];
  openHome: boolean;
  selectedHome: Home;
  openLead: boolean;
  payment: number;
}

class ResultsPage extends Component<ResultsPageProps, ResultsPageState> {

  constructor(props: ResultsPageProps) {
    super(props);
    this.state = {
      loading: false,
      county: '',
      homes: [],
      option: emptyOption,
      counties: [],
      openHome: false,
      selectedHome: emptyHome,
      openLead: false,
      payment: 0
    }
  }

  componentDidMount = async () => {
    // mounted
    this.fetchAll();
  }

  fetchAll = async (county?: string) => {

    this.setState({
      loading: true
    })

    const payment = parseInt(this.props.match.params.payment, 10);
    const qs = queryString.parse(this.props.location.search);
    const optionStr: any = qs.option;

    const optionId = parseInt(optionStr);
    const today = moment().format('YYYY-MM-DD');

    const resCache = await axios.get(`${AuthService.getApiUrls().core}/cache/${today}-options-${payment}`);
    const body = JSON.parse(resCache.data.body);
    const option = find(body, { optionId: optionId }) as Option;
    console.log('option', option);

    let countrySelected = county ? county : "Alameda";
    if (!county) {
      const counties: SelectItem[] = [];
      if (option.counties.length) {
        option.counties.forEach((countyName) => {
          if (countyName) {
            counties.push({
              name: countyName,
              value: countyName
            });
          }
        });
        countrySelected = option.counties[0];
      }
      this.setState({
        counties,
        county: countrySelected
      })
    }

    const homeRes = await axios.get(`${AuthService.getApiUrls().home}/homes?payment=${payment}&option=${qs.option}&county=${countrySelected}&count=0`);

    this.setState({
      loading: false,
      homes: homeRes.data.homes,
      option,
      payment
    })

  }

  handleNavigate = (route: string) => {
    this.props.history.push(route);
  }

  onCountyChange = (event: any) => {
    this.setState({ county: event.target.value });
    this.fetchAll(event.target.value);
  }

  onViewHome = async (home: Home) => {
    this.setState({ loading: true });
    // get home
    const homeRes = await axios.get(`${AuthService.getApiUrls().home}/home/${home.id}`);
    const fullHome = homeRes.data;

    console.log('fullHome', fullHome);

    // view 
    this.setState({
      loading: false,
      selectedHome: fullHome,
      openHome: true
    });

  }

  handleCloseHome = (home: Home, option: Option, action: string) => {

    if (action === 'request') {
      this.setState({
        openLead: true
      });
    }

    this.setState({
      openHome: false
    });
  }

  handleLeadClick = async (name: string, email: string, phone: string) => {

    try {

      const newLead: LeadEntity = {
        id: "",
        type: LeadTypes.RequestInfo,
        name,
        email,
        phone,
        message: "",
        addressId: this.state.selectedHome.listingRid.toString(),
        address: HomeService.getAddressFromHome(this.state.selectedHome),
        estimatedValue: this.state.selectedHome.listingPrice,
        loanBalance: 0,
        dreamRate: 0,
        createdDate: 0
      }

      await axios.post(`${AuthService.getApiUrls().site}/lead`, newLead);
      this.setState({ openLead: false });

      this.props.openSnackbar('Request sent! We will contact you very soon.');

    } catch (error) {
      console.log('error', error);
      this.setState({ openLead: false });
    }

  }

  handleLeadClose = () => {
    this.setState({
      openLead: false
    });
  }

  onSave = () => {
    // save
  }

  render() {

    return (
      <AppContainer showOption={false} private={false} maxWidth="lg" openSignup={false} color="secondary">
        <Loading open={this.state.loading} />
        <NavBar route="Search by Payment" icon={iconReverseMortgage} onClick={() => this.handleNavigate(`/payment/options/${this.state.payment}`)} />
        <HomeDetail 
          open={this.state.openHome} 
          home={this.state.selectedHome} 
          option={this.state.option}
          onClose={this.handleCloseHome} 
        />
        <Lead
          open={this.state.openLead}
          home={this.state.selectedHome} 
          onClick={this.handleLeadClick}
          onClose={this.handleLeadClose}
        />
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <div style={styles.maxPurchase}>Max Purchase: <span style={styles.number}>{ numeral(this.state.option.maxPurchase).format('$0,0') }</span></div>
            <div style={styles.maxPurchase}>Down payment: <span style={styles.number}>{ numeral(this.state.option.downPayment).format('$0,0') }</span></div>
          </Grid>
          <Grid item xs={4}>
            <SelectInput
              title=''
              value={this.state.county}
              onChange={this.onCountyChange}
              values={this.state.counties}
              fullWidth
            />
          </Grid>
        </Grid>
        <div style={styles.containerBody} className="items">
          {this.state.homes.map((home, index) => (
            <ResultItem
              key={index}
              onClick={this.onViewHome}
              onSave={this.onSave}
              cardId={index}
              item={home}
            />
          ))}
        </div>
      </AppContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    signup: state.auth.signup,
    auth: state.auth.auth,
    session: state.session.session,
    apiUrls: state.site.apiUrls
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, setSignup, openSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(ResultsPage));
