import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
// material
import { Grid, Container, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// compontent
import AddressSearch from '../../../components/site/AddressSearch';
import EmailInput from '../../../components/inputs/EmailInput';
import TextInput from '../../../components/inputs/TextInput';
import ActionButton from '../../../components/buttons/ActionButton';

// 
import { Session } from '../../../entities/auth';
import { LeadTypes } from '../../../entities/site';

interface HomeSectionContactProps {
    history: any;
    id: string;
    orientation: string;
    image: string;
    title: string;
    session: Session;
    onClick(name: string, email: string, message: string): void;
}


const HomeSectionContact: React.FC<HomeSectionContactProps> = (props) => {

    const classes = useStyles({});

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const handleClick = () => {
        props.onClick(name, email, message);
    }

    const containerClass = (props.orientation === 'right' ? classes.containerRight : classes.containerLeft)

    return (
        <div id={props.id} className={containerClass}>
            <Container maxWidth="lg" >
                <Grid container spacing={8}>
                    {props.orientation === 'right' &&
                        <React.Fragment>
                            <Grid item xs={12} md={6}>
                                <Hidden smDown>
                                    <div className={classes.imageContainer}><img src={props.image} className={classes.image} /></div>
                                </Hidden>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.title}>Contact Us</div>
                                <div className={classes.inputContainer}>
                                    <TextInput
                                        title="Name"
                                        type="string"
                                        placeholder=""
                                        result={name}
                                        onChange={(event: any) =>
                                            setName(event.target.value)
                                        }
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextInput
                                        title="Email"
                                        type="string"
                                        placeholder=""
                                        result={email}
                                        onChange={(event: any) =>
                                            setEmail(event.target.value)
                                        }
                                    />
                                </div>
                                <div className={classes.inputContainer}>
                                    <TextInput
                                        title="Message"
                                        type="string"
                                        placeholder=""
                                        result={message}
                                        multiline
                                        onChange={(event: any) =>
                                            setMessage(event.target.value)
                                        }
                                    />
                                </div>
                                <ActionButton
                                    onClick={handleClick}
                                    name="Send"
                                    size="sm"
                                    fullWidth
                                />
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    containerRight: {
        backgroundColor: '#F4F7FC',
        paddingTop: 85,
        paddingBottom: 85
    },
    containerLeft: {
        backgroundColor: '#FFF',
        paddingTop: 85,
        paddingBottom: 85
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    image: {
        width: 500,
        borderRadius: 20
    },
    title: {
        color: '#596675',
        fontSize: 30,
        paddingBottom: 20,
        display: 'flex',
        alignItems: 'center'
    },
    inputTitle: {
        color: '#4A607C',
        fontSize: 16
    },
    inputContainer: {
        paddingBottom: 20
    }
}));

export default withRouter<any, any>(HomeSectionContact);