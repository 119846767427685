import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Grid, Hidden } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import numeral from 'numeral';
import ActionButton from '../../../components/buttons/ActionButton';
// import TextInput from '../inputs/TextInput';

import { Home, Image } from '../../../entities/home';
import { Option } from '../../../entities/option';
import { Close } from '@material-ui/icons';

const home = process.env.PUBLIC_URL + '/home.png';
const bathroomIcon = process.env.PUBLIC_URL + '/galilaio/batg.png';
const roomIcon = process.env.PUBLIC_URL + '/galilaio//bed.png';

interface LeadProps {
  open: boolean;
  home: Home;
  option: Option;
  onClose(home: Home, option: Option, action: string): void;
}

const HomeDetail: React.FC<LeadProps> = (props: LeadProps) => {

  const classes = useStyles({});
  // const [name, setName] = useState<string>("");


  const handleLeadClick = () => {
    // props.onClick(name, email, phone);
  }

  useEffect(() => {
    // loading
  }, []);

  return (
    <div className={classes.container}>
      <Dialog open={props.open} onClose={() => props.onClose(props.home, props.option, '')} fullWidth maxWidth={'md'}>
        <div className={classes.right}><Close onClick={() => props.onClose(props.home, props.option, '')} className={classes.closeIcon} /></div>
        <DialogContent>
          <div className={classes.modalContainer}>
            <Grid container>
              <Grid xs={12} md={8}>
                <Hidden mdUp>
                  <div className={classes.homeAmount}>{numeral(props.home.listingPrice).format('$0,0')}</div>
                </Hidden>
                <Carousel showIndicators={false} >
                  {props.home.images.map((image: Image, index) => (
                    <div>
                      <img key={image.key} src={image.url} />
                    </div>
                  ))}
                </Carousel>
              </Grid>
              <Grid xs={12} md={4}>
                <div className={classes.homeDetailContainer}>
                  <Hidden smDown>
                    <div className={classes.homeAmount}>{numeral(props.home.listingPrice).format('$0,0')}</div>
                  </Hidden>
                  <div className={classes.homeAddress}>{props.home.streetNumber} {props.home.streetName}</div>
                  <div className={classes.homeAddress}>{props.home.city}, {props.home.zipCode}</div>
                  <div className={classes.homeDetails}>
                    <div className={classes.homeItem}><img src={roomIcon} className={classes.icon} />{props.home.bedrooms}</div>
                    <div className={classes.homeItem}><img src={bathroomIcon} className={classes.icon} />{props.home.fullBathrooms}</div>
                    <div className={classes.homeItemSq}>{numeral(props.home.apxSqFtPrimaryRes).format('0,0')} sq ft</div>
                  </div>
                  <div className={classes.homeDesc}>{props.home.propertyDescription}</div>
                  <div className={classes.button}>
                    <ActionButton
                      onClick={() => props.onClose(props.home, props.option, 'request')}
                      name="Request Information"
                      size="sm"
                      fullWidth
                    />
                  </div>
                  {/* <div className={classes.button}>
                    <ActionButton
                      onClick={() => props.onClose(props.home, props.option, 'cash-flow')}
                      name="Estimate Cash Flow"
                      size="sm"
                      fullWidth
                    />
                  </div>
                  <div className={classes.button}>
                    <ActionButton
                      onClick={() => props.onClose(props.home, props.option, 'payment-details')}
                      name="View Payment Details"
                      size="sm"
                      fullWidth
                    />
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
export default HomeDetail;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
    },
    modalContainer: {
    },
    sliderContainer: {
      height: 200
    },
    right: {
      paddingTop: 10,
      paddingRight: 10,
      textAlign: 'right'
    },
    modalTitle: {
      fontSize: 16
    },
    inputContainer: {
      marginTop: 10,
      marginBottom: 10
    },
    homeDetailContainer: {
      marginLeft: 20,
      borderRadius: 4,
      backgroundColor: '#F1F1F1',
      padding: 20,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    homeAmount: {
      color: '#009FDB',
      fontSize: 34,
      paddingBottom: 20,
    },
    homeAddress: {
      fontSize: 20,
    },
    homeDesc: {
      paddingTop: 20,
      color: '#666666',
      fontSize: 14
    },
    homeDetails: {
      display: 'flex'
    },
    homeItem: {
      padding: 10,
      fontSize: 24,
      [theme.breakpoints.down('sm')]: {
        padding: 5,
      },
    },
    homeItemSq: {
      padding: 10,
      fontSize: 18,
      [theme.breakpoints.down('sm')]: {
        padding: 5
      }
    },
    icon: {
      width: 30,
      paddingRight: 10
    },
    button: {
      marginTop: 10
    },
    closeIcon: {
      fontSize: 32
    }
  }),
);