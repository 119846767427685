import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from 'axios';
import Auth from '@aws-amplify/auth';

import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
// app
import { Auth as AuthEntity } from '../../../entities/auth';
import { Message, MessageTypes } from '../../../entities/site';
// containers
import RowContainer from '../../../containers/Row';
import Modal from '../../../containers/Modal';
// components
import TextInput from '../../../components/inputs/TextInput';
import ActionButton from '../../../components/buttons/ActionButton';
// services
import AuthService from '../../../services/auth';
// entities
import { TeamUser, Team, emptyTeam } from '../../../entities/site';

interface AddTeamUserProps {
  match?: any;
  open: any;
  teamUser: TeamUser;
  team: Team;
  auth: AuthEntity;
  onClose(msg?: string): void
}

interface AddTeamUserState {
  loading: boolean;
  team: Team;
  name: string;
  email: string;
  phoneNumber: string;
  canText: boolean;
  externalId: string;
  message: Message;
  disabled: boolean;
}

class AddTeamUser extends Component<AddTeamUserProps, AddTeamUserState> {

  constructor(props: AddTeamUserProps) {
    super(props);
    this.state = {
      loading: false,
      team: emptyTeam,
      name: '',
      email: '',
      phoneNumber: '',
      canText: false,
      externalId: '',
      message: {
        message: '',
        messageType: MessageTypes.Notification
      },
      disabled: false
    }
  }

  componentDidMount = () => {

    // mount

    // if (this.props.teamUser && this.props.teamUser.id) {
    //   this.fetchTeamUser();
    // }

  }

  componentDidUpdate(prevProps: AddTeamUserProps, prevState: AddTeamUserState) {
    if (this.props.teamUser && prevProps.teamUser.id !== this.props.teamUser.id) {
      this.fetchTeamUser();
    }
  }

  fetchTeamUser = async () => {

    try {

      const teamUserRes = await axios.get(`${AuthService.getApiUrls().site}/team/user/${this.props.teamUser.id}`);

      this.setState({
        name: teamUserRes.data.name,
        email: teamUserRes.data.email,
        phoneNumber: (teamUserRes.data.phone ? teamUserRes.data.phone : ''),
        canText: (teamUserRes.data.canText !== undefined ? teamUserRes.data.canText : false),
        externalId: (teamUserRes.data.meta ? teamUserRes.data.meta : '')
      });

    } catch (error) {
      console.log('error', error);
    }

  }

  onNameChange = (event: any) => {
    this.setState({ name: event.target.value });
  }

  onEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  }

  onPhoneNumberChange = (event: any) => {
    this.setState({ phoneNumber: event.target.value });
  }

  onCanTextChange = (event: any) => {
    this.setState({ canText: event.target.checked });
  }

  onExternalIdChange = (event: any) => {
    this.setState({ externalId: event.target.value });
  }

  handleSave = async () => {
    // sign up
    try {
      if (!this.checkForm()) {
        return;
      }

      this.setState({
        disabled: true
      });

      if (this.props.teamUser && this.props.teamUser.id) {

        // update
        const updateData = this.props.teamUser;
        updateData.name = this.state.name;
        updateData.email = this.state.email;
        updateData.phone = this.state.phoneNumber;
        updateData.canText = this.state.canText;
        updateData.meta = this.state.externalId;

        await axios.put(`${AuthService.getApiUrls().site}/team/user/${this.props.teamUser.id}`, updateData);

        this.handleClose('Successfully updated team member');

      } else {

        let userId = null;

        // check if user exists
        const user = await AuthService.getUserByEmail(this.state.email);
        if (user) {
          userId = user.id;
          // send email
          const emailData = {
            to: user.email,
            templateName: 'homewize_team',
            templateData: {
              team: this.props.team.name,
              username: user.email,
              url: 'https://dev.homewize.io/auth/login',
            }
          };
          await axios.post(`${AuthService.getApiUrls().core}/sendemail`, emailData);
        } else {
          // create new cognito user
          const createUserRes = await AuthService.createUser(this.state.email, 'Password1,', this.state.name, this.state.phoneNumber, true, true);
          userId = createUserRes.id;
        }

        // create new team user
        const dataTeamUser = {
          teamId: this.props.team.id,
          userId: userId,
          teamName: this.props.team.name,
          teamType: this.props.team.type,
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phoneNumber,
          canText: this.state.canText,
          externalId: this.state.externalId,
          role: 'teamuser'
        };

        // save new team user
        await axios.post(`${AuthService.getApiUrls().site}/team/user`, dataTeamUser);

        this.handleClose('Successfully saved team member');

      }
    } catch (error) {
      console.log('error', error);
      this.setState({ disabled: false });
      this.setState({ message: { message: 'Error saving loan officer', messageType: MessageTypes.Error } });
    }
  }

  resetForm = () => {
    this.setState({
      disabled: false,
      loading: false,
      name: '',
      email: '',
      phoneNumber: '',
      canText: false,
      externalId: ''
    });
  }

  checkForm = () => {
    let error = '';
    if (!this.state.name) {
      error = 'Name required';
    }
    if (!this.state.email) {
      error = 'Email required';
    }
    if (!this.state.phoneNumber) {
      error = 'Phone Number required';
    }
    // if (!this.state.externalId) {
    //   error = 'License Number required';
    // }
    if (!error) {
      this.setState({ message: { message: error, messageType: MessageTypes.Error } });
      return true;
    }
    return false;
  }

  handleClose = (msg?: string) => {
    this.resetForm();
    this.props.onClose(msg);
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        title="Loan Officer"
        onClose={this.handleClose}
        message={this.state.message}
      >
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextInput
                title="Name"
                type="string"
                placeholder=""
                result={this.state.name}
                onChange={this.onNameChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                title="Email"
                type="string"
                placeholder=""
                result={this.state.email}
                onChange={this.onEmailChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                title="Cell Phone"
                type="string"
                placeholder=""
                result={this.state.phoneNumber}
                onChange={this.onPhoneNumberChange}
              />
            </Grid>
            <Grid item xs={4} style={{
              display: 'flex',
              alignItems: 'flex-end'
            }}>
              <div style={{
                backgroundColor: '#F4F7FC',
                borderRadius: '4px',
                padding: '0px 10px 0px 10px',
                marginTop: 18
              }}
              >
                <FormControlLabel
                  style={{
                    fontSize: '14px',
                    color: '#5D759D'
                  }}
                  control={<Checkbox
                    name="checkedC"
                    checked={this.state.canText}
                    onChange={this.onCanTextChange}
                  />}
                  label="Ok to text"
                />
              </div>
            </Grid>
            <Grid item xs={5}>
              <TextInput
                title="License Number"
                type="string"
                placeholder=""
                result={this.state.externalId}
                onChange={this.onExternalIdChange}
              />
            </Grid>
          </Grid>
          <div style={{
            marginTop: 30
          }}>
            <RowContainer>
              <Grid container spacing={1} justify="flex-end">
                <Grid item xs={3} >
                  <ActionButton
                    name="Cancel"
                    onClick={this.handleClose}
                    fullWidth size="sm"
                    disabled={this.state.disabled}
                    type="secondary"
                  />
                </Grid>
                <Grid item xs={3} >
                  <ActionButton
                    name="Save"
                    onClick={this.handleSave}
                    fullWidth
                    size="sm"
                    disabled={this.state.disabled}
                  />
                </Grid>
              </Grid>
            </RowContainer>
          </div>
        </div>
      </Modal>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth
  };
};

// const mapDispatchToProps = (dispatch: any) => {
//   return bindActionCreators({ openSnackbar, closeSnackbar }, dispatch);
// };

export default connect(
  mapStateToProps
)(withRouter<any, any>(AddTeamUser));
