import axios from 'axios';
import { v1 as uuid } from 'uuid';
// entities
import { Campaign, CampaignContact } from '../entities/site';
import { MessageTemplate } from '../entities/notification';
import { User } from '../entities/user';

export interface MappedHeader {
  index: number;
  name: string;
}

class CampaignService {

  static buildImportChunks() {
    // 
  }

  static buildMessage(apiUrl: string, messageTemplate: MessageTemplate, campaign: Campaign, contact: CampaignContact): Promise<string | null> {

    return new Promise((resolve, reject) => {
      // base
      let message = messageTemplate.description;
      if (contact && contact.firstName) {
        message = message.replace('%contact_first_name%', contact.firstName);
        message = message.replace('%first_name%', contact.firstName);
      }
      // replace one-offs
      if (messageTemplate.id === '5fd96a15-cb75-494d-80c9-c68acf498826' ||
        messageTemplate.id === '487ce960-ec86-11ea-9821-47a5e4f981e2' ||
        messageTemplate.id === 'b76847a0-960c-11ea-bc39-ed1e807cf15e' ||
        messageTemplate.id === 'bd54bbe0-fd1c-11ea-ba2b-2d997aacf9ad' ||
        messageTemplate.id === 'c9e19fe0-fd1c-11ea-ba2b-2d997aacf9ad' ||
        messageTemplate.id === 'd6509ba0-fd1c-11ea-ba2b-2d997aacf9ad'
      ) {
        message = message.replace('%inquiry_address%', contact.address);
        message = message.replace('%agent_first_name%', campaign.contactName);
      }
      if (messageTemplate.id === '23dec900-947c-11ea-b566-35a4c4645b20') {
        message = message.replace('%ref_id%', contact.refId);
      }
      if (messageTemplate.id === '0fc7f190-d369-11ea-9c47-f7cce666be3c' || messageTemplate.id === '4c738f50-d369-11ea-9c47-f7cce666be3c') {
        message = message.replace('%street_address%', contact.address);
      }

      if (!campaign.redirectUrl) {
        return resolve(message);
      }

      // create short link
      axios.post(`${apiUrl}/shortlink`, {
        domain: CampaignService.getDomain(campaign),
        redirect: campaign.redirectUrl,
        title: campaign.name,
        typeId: campaign.id,
        type: 'campaign',
        contactId: contact.id
      }).then((res) => {

        if (message.indexOf('%short_link%') !== -1) {
          message = message.replace('%short_link%', res.data.shortLink);
        } else {
          message += ' ' + res.data.shortLink;
        }

        resolve(message);

      }).catch((error) => {
        reject(null);
      });

    });

  }

  static getDomain(campaign: Campaign) {
    let domain = 'galilaio.com';
    switch (campaign.client) {
      case 'homewize':
        domain = 'dev.homewize.io';
        break;
    }
    return domain;
  }

  static async sendMessage(notificationUrl: string, user: User | undefined, groupId: string, toPhoneNumber: string, fromPhoneNumber: string, message: string) {
    try {

      const messageBody = {
        toPhoneNumber,
        fromPhoneNumber,
        message,
        user
      };
      const messageSendRes = await axios.post(`${notificationUrl}/message/send`, messageBody);

      const messageData = {
        groupId,
        messageId: messageSendRes.data.id,
        fromPhoneNumber: messageSendRes.data.fromPhoneNumber,
        toPhoneNumber: messageSendRes.data.toPhoneNumber,
        message
      };
      const messageRes = await axios.post(`${notificationUrl}/message`, messageData);

      return true;

    } catch (error) {
      return false;
    }

  }

  static getSupportedColumns() {
    return [
      'firstName',
      'middleName',
      'lastName',
      'address',
      'city',
      'state',
      'zip',
      'lender',
      'loanAmount',
      'loanReceivedData',
      'loanType',
      'homeValue',
      'phoneNumber',
      'refId'
    ];
  }

}

export default CampaignService;