import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import numeral from 'numeral';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import ReactInterval from 'react-interval';
// app
import { updateSession, setSignup, openSnackbar } from '../../store/action-creators';
import UtilsService from '../../services/utils';
// containers
import AppContainer from '../../containers/App';
// components
import NavBar from '../../components/site/NavBar';
import HomeValue from '../../components/home/HomeValue';
import Loading from '../../components/site/Loading';
import AddressSearch from '../../components/site/AddressSearch';
import AddressBar from '../../components/home/AddressBar';
import Rate from './components/Rate';
import SelectRate from './components/SelectRate';
import ActionButton from '../../components/buttons/ActionButton';
import MessageBar from '../../components/message/MessageBar';
import Lead from '../../components/site/Lead';
// models
import { Session, Auth } from '../../entities/auth';
import { MessageTypes, Message, emptyMessage, Lead as LeadEntity, LeadTypes } from '../../entities/site';
import { Address, emptyAddress } from '../../entities/home';
import { emptyUserAddress, UserAddress } from '../../entities/user';
// style
import { colors } from '../../config/styles';
// material
import { Grid } from '@material-ui/core';
// service 
import HomeService from '../../services/home';
import AuthService from '../../services/auth';
// images
const iconPmi = process.env.PUBLIC_URL + '/images/icons/icon_pmi.svg';
const iconDreamRate = process.env.PUBLIC_URL + '/images/icons/icon_dream_rate.svg';


const styles: any = {
  container: {
    paddingTop: 30
  },
  loanContainer: {
    borderRadius: 4,
    backgroundColor: '#F4F7FC'
  },
  topText: {
    paddingTop: 20,
    paddingBottom: 40,
  },
  bottomBottom: {
    paddingTop: 20,
    paddingBottom: 20
  },
  subTitle: {
    color: '#6D839F',
    fontSize: 14,
    marginBottom: 10,
    marginTop: 20
  },
  text: {
    color: '#6D839F',
    fontSize: 14,
    marginBottom: 20,
  },
  newAddress: {
    color: '#00ADCC',
    fontSize: 'center'
  },
  pmiLogo: {
    width: 85
  },
  checkIcon: {
    fontSize: 36,
    color: '#05CA81'
  }
}

interface DreamRatePageProps {
  match?: any;
  history: any;
  auth: Auth;
  session: Session;
  setSignup(username: string, email: string, name: string, phone: string, isReset: boolean): void;
  updateSession(session: Session): void;
  openSnackbar(message: string): void;
}

interface DreamRatePageState {
  loading: boolean;
  loadingInterval: boolean;
  loadingText: string;
  loadingCounter: number;
  cash: number;
  percent: number;
  address: Address;
  userAddress: UserAddress;
  openSignup: boolean;
  msg: string;
  rate: number;
  message: Message;
  openLead: boolean;
  
}

class DreamRatePage extends Component<DreamRatePageProps, DreamRatePageState> {

  constructor(props: DreamRatePageProps) {
    super(props);
    this.state = {
      loading: false,
      loadingInterval: false,
      loadingText: 'loading...',
      loadingCounter: 0,
      openSignup: false,
      cash: 0,
      percent: 0,
      msg: '',
      address: emptyAddress,
      userAddress: emptyUserAddress,
      rate: 3.0,
      message: emptyMessage,
      openLead: false
    }
  }

  componentDidMount = async () => {

    const addressId = this.props.match.params.id;


    if (addressId) {
      await this.fetchAll(addressId);
    }

  }

  fetchAll = async (addressId: string) => {

    try {

      // fetch
      this.setState({ loading: true, loadingInterval: true, message: emptyMessage });

      const addressRes = await axios.get(`${AuthService.getApiUrls().home}/address/${addressId}`);

      if (addressRes.data) {

        // get user address 
        const userAddressRes: any = await HomeService.getUserAddress(addressRes.data);

        // TODO: fetch new address every month
        const calRes = HomeService.calculateCash(addressRes.data, userAddressRes.loanBalance);
        this.setState({
          userAddress: userAddressRes,
          address: addressRes.data,
          cash: calRes.cash,
          percent: calRes.percent
        });
      } else {

        const addressParts = HomeService.getAddressFromId(addressId);

        const propertyStatsRes = await axios.get(`${AuthService.getApiUrls().integrations}/property/calculate/?address=${addressParts.address}&city=${addressParts.city}&state=${addressParts.state}`);

        if (!propertyStatsRes.data.errorMessage) {
          const addressNew = propertyStatsRes.data;
          addressNew.id = addressId;
          const calRes = HomeService.calculateCash(addressNew, 0);
          this.setState({ 
            address: addressNew,
            cash: calRes.cash,
            percent: calRes.percent
          });
          await axios.post(`${AuthService.getApiUrls().home}/address`, addressNew);
        }

      }
      
      this.setState({ 
        loading: false,
        loadingInterval: false,
        loadingText: 'loading...',
        loadingCounter: 0
      });

    } catch (error) {
      const newSession = this.props.session;
      newSession.address = undefined;
      this.props.updateSession(newSession);
      this.setState({ 
        loading: false, 
        loadingInterval: false,
        loadingText: 'loading...',
        loadingCounter: 0,
        message: { message: 'Error saving loan officer', messageType: MessageTypes.Error } 
      });
    }

  }

  handleNavigate = (route: string) => {
    // this.props.history.push(`/estimator/cashflow/${this.state.address.id}`);
    this.props.history.push(route);
  }

  handleAddressSearch = async (address: Address) => {
    
    const currentAddressId = this.props.match.params.id;
    const newSession = this.props.session;
    newSession.address = address;
    this.props.updateSession(newSession);

    this.props.history.push(`/dream-rate/${address.id}`);

    if (currentAddressId && currentAddressId !== address.id) {
      this.fetchAll(address.id);
    }

  }

  handleUpdateLoanBalance = async (loanBalance: number) => {
    
    try {

      this.setState({ loading: true, message: emptyMessage });

      const user = AuthService.getUser();
      const currentAddress = { ...this.state.address };
      const userAddressData = { ...this.state.userAddress };

      userAddressData.loanBalance = loanBalance;
      userAddressData.userId = user ? user.id : '';
      userAddressData.addressId = this.state.address.id;
      currentAddress.loanBalance = loanBalance;

      if (user) {
        const saveRes = await HomeService.saveUserAddress(currentAddress, userAddressData);
      }

      // add loan balance to session
      const newSession = this.props.session;
      newSession.loanBalance = loanBalance;
      this.props.updateSession(newSession);

      const calRes = HomeService.calculateCash(currentAddress, loanBalance);

      this.setState({ 
        loading: false,
        userAddress: userAddressData,
        address: currentAddress,
        cash: calRes.cash,
        percent: calRes.percent
      });
    
    } catch (error) {
      this.setState({ loading: false, message: { message: 'Error saving loan officer', messageType: MessageTypes.Error } });
    }

  }

  handleResetAddress = () => {
    const newSession = this.props.session;
    newSession.address = undefined;
    this.props.updateSession(newSession);
    this.handleNavigate('/')
  }

  handleRateChange = (rate: number) => {
    this.setState({ rate });
  }

  handleSaveRate = () => {

    const user = AuthService.getUser();

    if (!user) {
      this.setState({openLead: true});
    } else {
      this.handleLeadClick(user.name, user.email, user.phone);
    }
    
    // this.setState({loading: true});

    // const messageBody = {
    //   toPhoneNumber: '+19168656917',
    //   // toPhoneNumber: '+17609852619',
    //   fromPhoneNumber: '+19259623762',
    //   message: 'We received your desired interest rate. We will contact you when the market improves',
    //   user: {id: 'testing'}
    // };
    // axios.post(`https://f03hx98ote.execute-api.us-west-2.amazonaws.com/dev/message/send`, messageBody).then(() => {
    // // axios.get('https://hooks.zapier.com/hooks/catch/3371176/erxdhh/').then(() => {
      
    //   this.props.openSnackbar('Saved! We will contact you very soon.');

    //   this.setState({loading: false});

    // });

  }

  handleLeadClose = () => {

    this.setState({openLead: false});

  }

  handleLeadClick = async (name: string, email: string, phone: string) => {

    try {

      const newLead: LeadEntity = {
        id: "",
        type: LeadTypes.DreamRate,
        name,
        email,
        phone,
        message: "",
        addressId: this.state.address.id,
        address: this.state.address.address,
        estimatedValue: this.state.address.estimate,
        loanBalance: this.state.address.loanBalance,
        dreamRate: this.state.rate,
        createdDate: 0
      }

      await axios.post(`${AuthService.getApiUrls().site}/lead`, newLead);
      this.setState({openLead: false});

      this.props.openSnackbar('Request sent! We will contact you very soon.');

    } catch (error) {
      console.log('error', error);
      this.setState({openLead: false});
    }

  }

  handleLoadingInterval = () => {
    if (this.state.loadingCounter === 0) {
      this.setState({loadingText: 'Getting property details...'});
    } else if (this.state.loadingCounter === 1) {
      this.setState({loadingText: 'Calculating property estimates...'});
    } else {
      this.setState({loadingText: 'Finishing up property calculations...'});
    }
    this.setState({loadingCounter: this.state.loadingCounter + 1});
  }

  render() {

    return (
      <AppContainer showOption={false} private={false} maxWidth="lg" openSignup={this.state.openSignup} color="secondary">
          <Loading open={this.state.loading} text={this.state.loadingText} />
          <ReactInterval timeout={5000} enabled={this.state.loadingInterval} callback={this.handleLoadingInterval} />
          <NavBar route="Dream Rate" icon={iconDreamRate} onClick={() => this.handleNavigate('/')} />
          <MessageBar message={this.state.message} />
          <Lead 
            open={this.state.openLead} 
            onClick={this.handleLeadClick}
            onClose={this.handleLeadClose} 
          />

          { !this.state.address.id  &&
            <AddressSearch
              session={this.props.session}
              onClick={this.handleAddressSearch}
            />
          }

          { this.state.address.id  &&
            <div>

              <AddressBar address={this.state.address} onClick={this.handleResetAddress} />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <HomeValue 
                    estimate={this.state.address.estimate}
                    loanBalance={this.state.userAddress.loanBalance}
                    valuePercent={this.state.percent}
                    equity={this.state.cash}
                    onSave={this.handleUpdateLoanBalance}
                  />
                </Grid>
              </Grid>
              <div style={{marginTop: 40}}>
                <Grid container spacing={8} style={styles.loanContainer}>
                  <Grid item xs={12} md={6}>
                    <Rate rate={this.state.rate} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectRate rate={this.state.rate} onChange={this.handleRateChange} onClick={this.handleSaveRate} />
                  </Grid>
                </Grid>
              </div>

            </div>
          }   
      </AppContainer>
    )
  }

}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators({ updateSession, setSignup, openSnackbar }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(DreamRatePage));
