import HomePage from '../pages/home/Home';
import TermsConditionsPage from '../pages/home/TermsConditions';
import PrivacyPolicyPage from '../pages/home/PrivacyPolicy';

// public
import ShortLinkPage from '../pages/shortlink/ShortLink';
import PmiRemovalPage from '../pages/pmi/Removal';
import PaymentDesignCenter from '../pages/payment/DesignCenter';
import DreamRatePage from '../pages/rate/DreamRate';

import PaymentSearchPage from '../pages/payment/Search';
import PaymentOptionsPage from '../pages/payment/Options';
import PaymentResultsPage from '../pages/payment/Results';
import LoginPage from '../pages/auth/Login';
import ContactPage from '../pages/contact/Contact';

// auth 
import ResetPage from "../pages/auth/Reset";

// private
import ProfilePage from '../pages/profile/Profile';
import NotificationsPage from '../pages/profile/Notifications';
import LoanOfficerPage from '../pages/profile/LoanOfficer';
import ProfileMortgagePage from '../pages/profile/ProfileMortgage';
import OrganizationsPage from '../pages/team/Organizations';
import OrganizationPage from '../pages/team/Organization';
import BranchPage from '../pages/team/Branch';
import LoanOfficersPage from '../pages/team/TeamUser';
// admin
import AdminPage from '../pages/admin/Admin';
import AdminUsersPage from '../pages/admin/AdminUsers';
// campaigns
import CampaignsPage from "../pages/campaign/Campaigns";
import CampaignPage from "../pages/campaign/Campaign";
import NewCampaignPage from "../pages/campaign/NewCampaign";
import NewCampaignDetailsPage from "../pages/campaign/NewCampaignDetails";
import NewCampaignTemplatePage from "../pages/campaign/NewCampaignTemplate";
import NewCampaignBilling from "../pages/campaign/NewCampaignBilling";
import NewCampaignTimeline from "../pages/campaign/NewCampaignTimeline";

const routes = {
  public: [
    {
      name: "Shortlink",
      path: "/l/:id",
      exact: true,
      component: ShortLinkPage,
    },
    {
      name: 'Home',
      path: '/',
      exact: true,
      component: HomePage
    },
    {
      name: 'TermsConditions',
      path: '/terms-conditions',
      exact: true,
      component: TermsConditionsPage
    },
    {
      name: 'PrivacyPolicy',
      path: '/privacy-policy',
      exact: true,
      component: PrivacyPolicyPage
    },
    {
      name: 'Login',
      path: '/auth/login',
      exact: true,
      component: LoginPage
    },
    {
      name: 'Contact',
      path: '/contact',
      exact: true,
      component: ContactPage
    },
    {
      name: 'PMI Removal',
      path: '/pmi-removal/:id',
      exact: true,
      component: PmiRemovalPage
    },
    {
      name: 'PMI Removal',
      path: '/pmi-removal',
      exact: true,
      component: PmiRemovalPage
    },
    {
      name: 'Payment Design Center',
      path: '/payment-design-center',
      exact: true,
      component: PaymentDesignCenter
    },
    {
      name: 'Payment Design Center',
      path: '/payment-design-center/:id',
      exact: true,
      component: PaymentDesignCenter,
      roles: []
    },
    {
      name: 'Payment Search',
      path: '/payment/search',
      exact: true,
      component: PaymentSearchPage,
      roles: []
    },
    {
      name: 'Payment Options',
      path: '/payment/options/:id',
      exact: true,
      component: PaymentOptionsPage,
      roles: []
    },
    {
      name: 'Payment Results',
      path: '/payment/results/:payment',
      exact: true,
      component: PaymentResultsPage,
      roles: []
    },
    {
      name: 'Dream Rate',
      path: '/dream-rate',
      exact: true,
      component: DreamRatePage,
      roles: []
    },
    {
      name: 'Dream Rate',
      path: '/dream-rate/:id',
      exact: true,
      component: DreamRatePage,
      roles: []
    }
  ],
  private: [
    // auth
    {
      name: "Reset Password",
      path: "/auth/reset",
      exact: true,
      component: ResetPage,
      roles: ['user']
    },
    // admin
    {
      name: 'Admin',
      path: '/admin',
      exact: true,
      component: AdminPage,
      roles: ['admin']
    },
    {
      name: 'Admin',
      path: '/admin/users',
      exact: true,
      component: AdminUsersPage,
      roles: ['superadmin']
    },
    // {
    //   name: 'Admin',
    //   path: '/admin/addresses',
    //   exact: true,
    //   component: AdminAddressesPage,
    //   roles: 'super-admin'
    // },
    // {
    //   name: 'Admin',
    //   path: '/admin/logs',
    //   exact: true,
    //   component: AdminLogsPage,
    //   roles: 'super-admin'
    // },
    {
      name: 'Profile',
      path: '/profile',
      exact: true,
      component: ProfilePage,
      roles: ['user', 'admin']
    },
    {
      name: 'Notifications',
      path: '/notifications',
      exact: true,
      component: NotificationsPage,
      roles: ['user', 'admin']
    },
    {
      name: 'LoanProfile',
      path: '/profile/loanofficer/:id',
      exact: true,
      component: LoanOfficerPage,
      roles: ['user', 'admin']
    },
    {
      name: 'Organizations',
      path: '/organizations',
      exact: true,
      component: OrganizationsPage,
      roles: ['user', 'admin']
    },
    {
      name: 'Organization',
      path: '/organization/:id',
      exact: true,
      component: OrganizationPage,
      roles: ['user', 'admin']
    },
    {
      name: 'Organization Branches',
      path: '/organization/:id/branch/:branchid',
      exact: true,
      component: BranchPage,
      roles: ['user', 'admin']
    },
    {
      name: 'Organization Branches',
      path: '/organization/:id/branch/:branchid/user/:userid',
      exact: true,
      component: LoanOfficersPage,
      roles: ['user', 'admin']
    },
    {
      name: 'Profile Mortgage',
      path: '/profile/mortgage',
      exact: true,
      component: ProfileMortgagePage,
      roles: ['user', 'admin']
    },
    // campaigns
    {
      name: "Campaigns",
      path: "/campaigns",
      exact: true,
      component: CampaignsPage,
      roles: ["admin", "agent", "campaigns", "user"],
    },
    // {
    //   name: "Campaign Clicks",
    //   path: "/campaign/clicks",
    //   exact: true,
    //   component: CampaignClicksPage,
    //   roles: ["admin", "agent", "campaigns", "user"],
    // },
    {
      name: "Campaign",
      path: "/campaign/:id",
      exact: true,
      component: CampaignPage,
      roles: ["admin", "agent", "campaigns", "user"],
    },
    {
      name: "New Campaign",
      path: "/campaign/new/start",
      exact: false,
      component: NewCampaignPage,
      roles: ["admin", "agent", "campaigns", "user"],
    },
    {
      name: "New CampaignDetails",
      path: "/campaign/new/details",
      exact: true,
      component: NewCampaignDetailsPage,
      roles: ["admin", "agent", "campaigns", "user"],
    },
    {
      name: "New CampaignTemplate",
      path: "/campaign/new/template",
      exact: true,
      component: NewCampaignTemplatePage,
      roles: ["admin", "agent", "campaigns", "user"],
    },
    {
      name: "New CampaignTimeline",
      path: "/campaign/new/timeline",
      exact: true,
      component: NewCampaignTimeline,
      roles: ["admin", "agent", "campaigns", "user"],
    },
    {
      name: "New CampaignBilling",
      path: "/campaign/new/billing",
      exact: true,
      component: NewCampaignBilling,
      roles: ["admin", "agent", "campaigns", "user"],
    },
  ]
}

export default routes;