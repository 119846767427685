import { SelectItem } from '../components/inputs/SelectInput';

export interface Site {
  snackbar: boolean;
  snackbarMsg: string;
  apiUrls?: APIUrl;
}

export interface APIUrl {
  core: string;
  home: string;
  integrations: string;
  user: string;
  notifications: string;
  site: string; 
}

export enum APIUrls {
  Core,
  Home,
  Integrations,
  User
}

export interface Message {
  message: string;
  messageType: MessageTypes;
}

export enum MessageTypes {
  Error = "error",
  Notification = "notification",
  Warning = "warning"
}

export const emptyMessage = {
  message: '',
  messageType: MessageTypes.Notification
}

export interface Address {
  id: string;
  address: string;
  estimate: number;
  loanBalance: number;
  createdDate: number;
}

export interface Log {
  id: string;
  body: string;
  createdDate: number;
}

export interface TabType{
  name: string;
  value: string;
}

export interface Collaborator {
  id: string;
  name: string;
  type: string;
}

export interface CampaignSession {
  campaign: Campaign;
  contacts: CampaignContact[][];
  contactErrors: CampaignContact[];
  collaborators: Collaborator[];
}

export const campaignStatusList: SelectItem[] = [
  {
    name: 'New',
    value: 'new'
  },
  {
    name: 'Started',
    value: 'started'
  },
  {
    name: 'Active',
    value: 'active'
  },
  {
    name: 'Archived',
    value: 'archived'
  },
  {
    name: 'Deleted',
    value: 'deleted'
  }
]

export enum CampaignStatus {
  New,
  Complete,
  Active,
  Archived,
  Deleted
}

export const emptyCampaign = {
  id: '', 
  name: '',
  type: '',
  client: '',
  collaboratorType: '',
  collaboratorId: '',
  status: '',
  templateId: '',
  redirectUrl: '',
  contactName: '',
  startDate: '',
  endDate: '',
  daysOfWeek: '',
  messagesPerDay: 0,
  createdDate: 0,
  totalContacts: 0,
  totalSent: 0,
  totalDoNotCall: 0,
  totalClicks: 0,
  totalActive: 0,
  totalOptOuts: 0,
  totalNonResponsive: 0
};

export interface Campaign {
  id: string; 
  name: string; 
  type: string; // refinance.....
  client: string;
  collaboratorType: string; // individual, team, branch
  collaboratorId: string; // id to get individual, team, branch
  status: string; // new, active, archived
  templateId: string;
  redirectUrl: string;
  contactName: string;
  startDate: string;
  endDate: string;
  daysOfWeek: string;
  messagesPerDay: number;
  createdDate: number;
  totalContacts: number;
  totalSent: number;
  totalDoNotCall: number;
  totalClicks: number;
  totalActive: number;
  totalOptOuts: number;
  totalNonResponsive: number;
}

export const emptyCampaignContact = {
  id: '',
  campaignId: '',
  fubId: 0,
  refId: '',
  firstName: '', 
  middleName: '', 
  lastName: '',
  phoneNumber: '',
  email: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  agent: '',
  lender: '',
  loanAmount: 0,
  loanType: '',
  homeValue: 0,
  status: '',
  createdDate: 0
}

export interface CampaignContact {
  id: string; 
  campaignId: string;
  fubId: number;
  refId: string;
  firstName: string; 
  middleName: string; 
  lastName: string;
  phoneNumber: string;
  email: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  agent: string;
  lender: string;
  loanAmount: number;
  loanType: string;
  homeValue: number;
  status: string;
  createdDate: number;
}

export interface CampaignContactES {
  _index: string;
  _type: string;
  _id: string;
  _score: number;
  _source: CampaignContact;
}

export enum CampaignContactStatus {
  New,
  Sent,
  Active,
  ClickThrough,
  OptOut,
  NonResponsive,
  DoNotCall,
  NewMessage
}

export interface CampaignContactList {
  firstName: string; 
  lastName: string;
  phoneNumber: string;
  email: string;
}

export interface CampaignStats {
  total: number;
  totalNew: number;
  totalSent: number;
  totalActive: number;
  totalDoNotCalls: number;
  totalOptOuts: number;
  totalClickThroughs: number;
  totalNonResponsive: number;
}

export interface Team {
  id: string;
  name: string;
  description: string;
  type: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  externalId: string;
  parentId: string;
  createdDate: number;
  teams: Team[];
  teamUsers: TeamUser[];
}

export const emptyTeam = {
  id: '',
  name: '',
  description: '',
  type: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
  externalId: '',
  parentId: '',
  createdDate: 0,
  teams: [],
  teamUsers: []
}

export enum TeamType {
  Team,
  Branch,
  Organization
}

export interface TeamList {
  teamId: string;
  teamName: string;
  teamType: string;
}

export enum TeamUserRole {
  Manager,
  Loanofficer,
  Member
}

export interface TeamUser {
  id: string;
  teamId: string;
  userId: string;
  teamName: string;
  teamType: string;
  name: string;
  email: string;
  createdDate: number;
  parentId?: string;
  phone?: string;
  role?: string;
  canText?: boolean;
  meta?: string;
}

export const emptyTeamUser = {
  id: '',
  teamId: '',
  userId: '',
  parentId: '',
  teamName: '',
  teamType: '',
  name: '',
  email: '',
  phone: '',
  role: '',
  externalId: '',
  canText: true,
  meta: '',
  createdDate: 0
}

export interface Lead {
  id: string;
  type: string;
  name: string;
  email: string;
  phone: string;
  message: string;
  addressId: string;
  address: string;
  estimatedValue: number;
  loanBalance: number;
  dreamRate: number;
  createdDate: number;
}

export enum LeadTypes {
  PmiRemoval="pmiRemoval",
  PaymentDesign="paymentDesign",
  WelcomePackage="welcomePackage",
  SolarSunScore="solarSunScore",
  DreamRate="dreamRate",
  Contact="contact",
  Incomplete="incomplete",
  PaymentSearch="PaymentSearch",
  RequestInfo="RequestInfo",
}