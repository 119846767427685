import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions  } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ActionButton from '../buttons/ActionButton';
import TextInput from '../inputs/TextInput';
import { Home } from '../../entities/home';

import HomeService from '../../services/home';

interface LeadProps {
  open: boolean;
  email?: string;
  home?: Home;
  onClose(completed: boolean): void;
  onClick(name: string, email: string, phone: string): void;
}
  
const Lead: React.FC<LeadProps> = (props: LeadProps) => {

  const classes = useStyles({});
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const handleLeadClick = () => {
    props.onClick(name, email, phone);
  }

  useEffect(() => {
   if (props.email) {
    setEmail(props.email);
   }
  }, [props.email]);

  return (
    <div className={classes.container}>
      <Dialog open={props.open} onClose={() => props.onClose(false)}>
        <DialogContent>
          <div className={classes.modalContainer}>
            <div className={classes.modalTitle}>Add your information, so one of our experts can reach out.</div>
            { props.home !== undefined &&  
              <div className={classes.modalTitleAddress}>Address: {HomeService.getAddressFromHome(props.home)}</div>
            }
            <div className={classes.inputContainer}>
              <TextInput
                title="Name"
                type="string"
                placeholder=""
                result={name}
                onChange={(event: any) => setName(event.target.value)}
              />
            </div>
            <div className={classes.inputContainer}>
              <TextInput
                title="Email"
                type="string"
                placeholder=""
                result={email}
                onChange={(event: any) => setEmail(event.target.value)}
              />
            </div>
            <div className={classes.inputContainer}>
              <TextInput
                title="Phone (optional)"
                type="string"
                placeholder=""
                result={phone}
                onChange={(event: any) => setPhone(event.target.value)}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <ActionButton 
            onClick={() => props.onClose(false)} 
            type="secondary"  
            name="Close"
            size="sm"
            textOnly
          /> 
          <ActionButton 
            onClick={handleLeadClick}  
            name="Send Request"
            size="sm"
          /> 
        </DialogActions>
      </Dialog>
    </div>
  )  
}
export default Lead;

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {

    },
    modalContainer: {

    },
    modalTitle: {
      fontSize: 16
    },
    modalTitleAddress: {
      paddingTop: 5
    },
    inputContainer: {
      marginTop: 10,
      marginBottom: 10
    }
  }),
);