import React from 'react';
import { withRouter } from "react-router-dom";
// material
import { Grid, Container, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// compontent
import AddressSearch from '../../../components/site/AddressSearch';
import EmailInput from '../../../components/inputs/EmailInput';

// 
import { Session } from '../../../entities/auth';
import { LeadTypes } from '../../../entities/site';

interface HomeSectionEmailProps {
    history: any;
    id: string;
    orientation: string;
    image: string;
    icon: string;
    title: string;
    description: string;
    session: Session;
    onClick(email: string): void;
}


const HomeSectionEmail: React.FC<HomeSectionEmailProps> = (props) => {

    const classes = useStyles({});

    const containerClass = (props.orientation === 'right' ? classes.containerRight : classes.containerLeft)

    return (
        <div id={props.id} className={containerClass}>
            <Container maxWidth="lg" >
                <Grid container spacing={8}>
                    {props.orientation === 'right' &&
                        <React.Fragment>
                            <Grid item xs={12} md={6}>
                                <Hidden smDown>
                                    <div className={classes.imageContainerMobile}><img src={props.image} className={classes.image} /></div>
                                </Hidden>
                                <Hidden mdUp>
                                    <div className={classes.imageContainer}><img src={props.image} className={classes.imageMobile} /></div>
                                </Hidden>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.titleContainer}>
                                    <div><img src={props.icon} className={classes.icon} /></div>
                                    <div className={classes.title}>{props.title}</div>
                                </div>
                                <div className={classes.description}>{props.description}</div>
                                <div>
                                    <EmailInput
                                        title="Enter Your Email"
                                        onClick={props.onClick}
                                    />
                                </div>
                            </Grid>
                        </React.Fragment>
                    }
                    {props.orientation === 'left' &&
                        <React.Fragment>
                            <Grid item xs={12} md={6}>
                                <Hidden mdUp>
                                    <div className={classes.imageContainerMobile}><img src={props.image} className={classes.imageMobile} /></div>
                                </Hidden>
                                <div className={classes.titleContainer}>
                                    <div><img src={props.icon} className={classes.icon} /></div>
                                    <div className={classes.title}>{props.title}</div>
                                </div>
                                <div className={classes.description}>{props.description}</div>
                                <div>
                                    <EmailInput
                                        title="Enter Your Email"
                                        onClick={props.onClick}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Hidden smDown>
                                    <div className={classes.imageContainer}><img src={props.image} className={classes.image} /></div>
                                </Hidden>
                            </Grid>
                        </React.Fragment>
                    }
                </Grid>
            </Container>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    containerRight: {
        backgroundColor: '#F4F7FC',
        paddingTop: 85,
        paddingBottom: 85
    },
    containerLeft: {
        backgroundColor: '#FFF',
        paddingTop: 85,
        paddingBottom: 85
    },
    titleContainer: {
        display: 'flex'
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    imageContainerMobile: {
        display: 'flex',
        justifyContent: 'center',
        padding: 10,
        marginBottom: 20
    },
    image: {
        width: 500,
        borderRadius: 20
    },
    imageMobile: {
        width: '100%',
        borderRadius: 20
    },
    icon: {
        width: 70,
        height: 70
    },
    title: {
        color: '#596675',
        fontSize: 30,
        paddingLeft: 20,
        display: 'flex',
        alignItems: 'center'
    },
    inputTitle: {
        color: '#4A607C',
        fontSize: 16
    },
    description: {
        color: '#8794A3',
        fontSize: 20,
        paddingTop: 30,
        paddingBottom: 60
    }

}));

export default withRouter<any, any>(HomeSectionEmail);