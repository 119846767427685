export interface Option {
  optionId: number;
  optionType: number;
  description: string;
  percentDown: number;
  homeCount: number;
  downPayment: number;
  maxPurchase: number;
  loanType: number;
  totalDown: number;
  loanAmount: number;
  taxes: number;
  mortgageInsurance: number;
  hazardInsurance: number;
  principalInterest: number;
  interestRate: number;
  aprPercent: number;
  counties?: string[]
}

export const emptyOption: Option = {
  optionId: 0,
  optionType: 0,
  description: '',
  percentDown: 0,
  homeCount: 0,
  downPayment: 0,
  maxPurchase: 0,
  loanType: 0,
  totalDown: 0,
  loanAmount: 0,
  taxes: 0,
  mortgageInsurance: 0,
  hazardInsurance: 0,
  principalInterest: 0,
  interestRate: 0,
  aprPercent: 0,
  counties: []
}