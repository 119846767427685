import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Option } from '../../../entities/option';
import ActionButton from '../../../components/buttons/ActionButton';
import OptionDetail from './OptionDetail';
import MoneyText from '../../../components/inputs/MoneyText';
import Hidden from '@material-ui/core/Hidden';
// import NeedHelpCard from '../../../components/cards/NeedHelp';
import ZapService from '../../../services/zap';
const flag = process.env.PUBLIC_URL + '/galilaio/flag.png';

const styles: any = {
  container: {
    backgroundColor: 'rgba(201,211,224,0.2)',
    borderRadius: 4,
    padding: 25,
    position: "relative",
  },
  containerVeteran: {
    backgroundColor: '#1177A8',
    borderRadius: 4,
    padding: 25,
    position: "relative",
    color: '#fff'
  },
  count: {
    position: "absolute",
    top: 3,
    left: 3,
    padding: "5px 10px 5px 10px",
    backgroundColor: "#F1F1F1",
    color: "#999999" 
  },
  homeCount: {
    fontSize: 32,
    color: "#333333",
    fontWeight: 600
  }, 
  homeText: {
    paddingTop: 10,
    paddingBottom: 20,
    fontSize: 14,
    color: "#777777",
  },
  optionVeteranContainer: {
    color: "#fff",
    fontSize: 12,
    display: 'flex',
    justifyContent: "space-between"
  },
  veteranTitle: {
    fontSize: 13,
    fontWeight: 800
  },
  veteranText: {
    paddingLeft: 10,
    textAlign: 'left'
  },
  veteranSubText: {
    float: 'right'
  },
  veteranBody: {
    marginTop: 15,
    marginBottom: 20
  },
  veteranImg: {
    width: 80
  },
  optionContainer: {
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    padding: "10px 10px 0px 10px",
    color: "#B0B0B0",
    fontSize: 13,
  },
  optionContainerItem: {
    paddingBottom: 10,
    display: 'flex',
    justifyContent: "space-between"
  },
  link: {
    padding: 20,
    color: "#71C7EC",
    fontSize: 14,
    cursor: "pointer",
    textAlign: 'center'
  },
  maxPurchase: {
    fontSize: 18,
    fontWeight: 800,
    color: '#727272'
  },
  downPayment: {
    fontSize: 14,
    fontWeight: 800,
    color: '#727272'
  }
}

interface OptionItemProps {
  optionId: number;
  option: Option;
  onClick(optionId: number): void;
  onHelpClick(): void;
}

interface OptionItemState {
  openDetails: boolean;
}

class OptionItem extends Component<OptionItemProps, OptionItemState> {
  constructor(props: OptionItemProps) {
    super(props);
    
    this.state = {
      openDetails: false
    }
    this.openDetials = this.openDetials.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClick = (optionId: number) => {
    this.setState({openDetails: false});
    this.props.onClick(optionId);
  }

  openDetials = () => {
    this.setState({openDetails: true});
  } 

  onClose = () => {
    this.setState({openDetails: false});
  }

  render() {
    const { option, optionId } = this.props;
    return (
      <div className="item">
        {option.optionType === 1 &&
          <div style={styles.container} >
            <div style={styles.count}>{optionId}</div>
            <div style={styles.homeCount}>{option.homeCount}</div>
            <div style={styles.homeText}>Homes Available</div>
            <div style={styles.optionContainer}>
                <div style={styles.optionContainerItem}>
                  <div>{option.percentDown.toFixed(2)}% Down Payment</div>
                  <div style={styles.downPayment}><MoneyText value={option.downPayment} /></div>
                </div>
                <div style={styles.optionContainerItem}>
                  <div>Max Purchase</div>
                  <div style={styles.maxPurchase}><MoneyText value={option.maxPurchase} /></div>
                </div>
            </div>
            <div style={styles.link} onClick={this.openDetials}>View Details</div>
            <ActionButton name="See Homes" onClick={() => this.onClick(optionId)} size="sm" fullWidth />
            <OptionDetail optionId={optionId} option={option} open={this.state.openDetails} onClick={this.onClick} onClose={this.onClose} />
          </div>
        }
        {option.optionType === 2 &&
          <div style={styles.containerVeteran}>
            <div style={styles.optionVeteranContainer}>
              <div>
                <Hidden mdDown>
                  <img src={flag} style={styles.veteranImg} />
                </Hidden>
              </div>
              <div style={styles.veteranText}>
                <div style={styles.veteranTitle}>Are you a veteran?</div>
                <div style={styles.veteranSubText}>Thank you for your service! We can never repay the debt owed for the service you gave to our country.</div>
              </div>
            </div>
            <div style={styles.veteranBody}>
              <div>
                You qualify for <span style={{fontWeight: 800, fontSize: 20}}><MoneyText value={option.maxPurchase} /></span>
              </div>
              <div>max purchase based on your payment.</div>
            </div>
            <ActionButton name="See Homes" onClick={() => this.onClick(optionId)} size="sm" fullWidth />
          </div>
        }
        {option.optionType === 3 &&
          <div>
            {/* <NeedHelpCard onClick={this.props.onHelpClick} type={"dark"} /> */}
          </div>
        }
      </div>
    );
  }
}
export default OptionItem;