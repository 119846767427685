import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { find } from "lodash";
import moment from "moment";
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, Box, Checkbox, Hidden } from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

// app
import {
  updateSession,
  openSnackbar,
  closeSnackbar,
  addAuth
} from "../../store/action-creators";
// containers
import AppContainer from "../../containers/App";
import ProfileContainer from "../../containers/Profile";
// components
import IconButton from "../../components/buttons/IconButton";
import AddressSearch from '../../components/site/AddressSearch';
import Loading from '../../components/site/Loading';
// entities
import { Auth as AuthEntity, Session } from "../../entities/auth";
import { Address, AddressSetting, emptyAddressSetting } from "../../entities/home";
import { Mortgage, UserRate } from "../../entities/profile";
import { UserAddress, User } from "../../entities/user";
import ActionButton from "../../components/buttons/ActionButton";
// service 
import AuthService from '../../services/auth';

const states = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming"
];

const styles: any = {
  container: {
    flex: 1,
    height: "100%",
    minHeight: "100%",
    marginBottom: 200,
    marginTop: 40
  },
  pageTitleSub: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: 700,
    marginTop: 20,
    marginBottom: 40
  },
  input: {
    marginTop: 10
  },
  sectionTitle: {
    textAlign: "left",
    fontSize: 14
  },
  mortgageContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: 20,
    border: "1px solid #F1F1F1"
  },
  mortgage: {
    textAlign: "left"
  },
  footerContainer: {
    marginTop: 40,
    borderTop: "1px solid #CCCCCC",
    paddingTop: 10
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
    height: 5,
    backgroundColor: '#ccc'
  }
};

interface ProfilePageProps {
  auth: AuthEntity;
  history: any;
  session: Session;
  addAuth(auth: AuthEntity): void;
  openSnackbar(msg: string): void;
  closeSnackbar(): void;
  updateSession(session: Session): void;
}

interface ProfilePageState {
  loading: boolean;
  mortgages: Mortgage[];
  rates: UserRate[];
  userId: string;
  name: string;
  lastName: string;
  textUpdates: boolean;
  email: string;
  phone: string;
  rate: number;
  showAddress: boolean;
  addresses: UserAddress[],
  addressSetting: AddressSetting;
}

class ProfilePage extends Component<ProfilePageProps, ProfilePageState> {
  constructor(props: ProfilePageProps) {
    super(props);
    this.state = {
      loading: false,
      mortgages: [],
      rates: [],
      userId: "",
      name: "",
      lastName: "",
      email: "",
      phone: "",
      textUpdates: false,
      rate: 0,
      showAddress: false,
      addresses: [],
      addressSetting: emptyAddressSetting
    };
  }

  componentDidMount = () => {
    this.fetchUserAddress();

    if (this.props.auth && this.props.auth.user) {
      this.setState({
        userId: this.props.auth.user.id,
        name: this.props.auth.user.name,
        email: this.props.auth.user.email,
        phone: this.props.auth.user.phone,
        textUpdates: this.props.auth.user.canText
      });
    }
  };;

  fetchUserAddress = async () => {

    try {

      this.setState({ loading: true });
      const addresses = await axios.get(`${AuthService.getApiUrls().user}/user/${this.props.auth.id}/address?type=all`);

      const addressesParsed = [];
      addresses.data.forEach((address) => {
        if (address) {
          addressesParsed.push(address);
        }
      });

      this.setState({
        loading: false,
        addresses: addressesParsed
      });

    } catch (error) {
      console.log('error', error);
    }

  }

  handleNavigate = () => {
    // navigate
    this.props.history.push("/profile/mortgage");
  };

  handleClick = (mortgageId: string) => {
    // set selected mortgage
    const mortgage = find(this.state.mortgages, { id: mortgageId });
    const newSession = this.props.session;
    newSession.selectedMortgage = mortgage;
    this.props.updateSession(newSession);
    this.props.history.push(`/estimator/cashflow/${mortgageId}`);
  };

  handleRateClick = (rateId: string) => {
    //
  };

  handleUpdateProfile = async () => {

    try {
      this.setState({loading: true});

      const userRes = await axios.get(`${AuthService.getApiUrls().user}/user/${this.state.userId}`);
      const newUser: User = userRes.data;
      newUser.name = this.state.name;
      newUser.phone = this.state.phone;
      newUser.canText = this.state.textUpdates;

      await axios.put(`${AuthService.getApiUrls().user}/user/${this.state.userId}`, newUser);

      const newAuth = {...this.props.auth};
      newAuth.user = newUser;

      this.props.addAuth(newAuth);

      this.setState({loading: false});

    } catch (error) {
      this.setState({loading: false});
    }

  };

  handleNameChange = (event: any) => {
    this.setState({ name: event.target.value });
  };

  handleEmailChange = (event: any) => {
    this.setState({ email: event.target.value });
  };

  handleTextUpdatesChange = (event: any) => {
    this.setState({ textUpdates: !this.state.textUpdates });
  };

  handlePhoneChange = (event: any) => {
    this.setState({ phone: event.target.value });
  };

  handleRateChange = (event: any) => {
    this.setState({ rate: event.target.value });
  };

  handleShowAddress = () => {
    this.setState({ showAddress: true })
  }

  handleResetPassword = () => {
    this.props.history.push(`/auth/reset`);
  }

  handleAddressSearch = async (address: Address) => {

    try {

      this.setState({ loading: true });

      // post user address
      const addressSettingsData: AddressSetting = emptyAddressSetting;
      addressSettingsData.userId = this.state.userId;
      addressSettingsData.addressId = address.id;

      await axios.post(`${AuthService.getApiUrls().home}/address/settings`, addressSettingsData);

      this.setState({ loading: false });

    } catch (error) {
      console.log('error', error);
    }

  }


  render() {
    return (
      <AppContainer loading={this.state.loading} private maxWidth="lg">
        <ProfileContainer>
          <Loading open={this.state.loading} />
          <Grid container>
            <Grid item xs={6} md={10}>
              <div className="title">Profile</div>
            </Grid>
            <Grid item xs={6} md={1}>
              <ActionButton
                name="Save"
                onClick={this.handleUpdateProfile}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className="sub-title">Personal info</div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <label className="ars-label">Name</label>
                  <input
                    className="ars-input"
                    type="text"
                    value={this.state.name}
                    onChange={this.handleNameChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <label className="ars-label">Email Address</label>
                  <input
                    className="ars-input"
                    type="text"
                    value={this.state.email}
                    onChange={this.handleEmailChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <label className="ars-label">Cell Number</label>
                  <input
                    className="ars-input"
                    type="text"
                    value={this.state.phone}
                    onChange={this.handlePhoneChange}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Box display="flex" alignItems="center" ml="-12px">
                    <Checkbox
                      color="primary"
                      checked={this.state.textUpdates}
                      onChange={this.handleTextUpdatesChange}
                      name="textUpdates"
                    />
                      Yes, please send me text updates
                    </Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={3}>
                  <ActionButton
                    textOnly
                    name="Reset Password"
                    onClick={this.handleResetPassword}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <div style={styles.divider} />
          <Grid container>
            <Grid item xs={10}>
              <div className="title">My Addresses</div>
            </Grid>
            <Grid item xs={1}>
              {/* <IconButton name="Address" onClick={this.handleShowAddress} icon={<AddCircleOutlineIcon />} /> */}
            </Grid>
            <Grid item xs={12}>
              {this.state.showAddress &&
                <AddressSearch
                  session={this.props.session}
                  background={false}
                  onClick={this.handleAddressSearch}
                />
              }
            </Grid>
            <Grid item xs={12}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Address</TableCell>
                    <Hidden smDown>
                      <TableCell>Baths</TableCell>
                      <TableCell>Beds</TableCell>
                      <TableCell>Estimate</TableCell>
                      <TableCell>Loan Balance</TableCell>
                      <TableCell>Created</TableCell>
                    </Hidden>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.addresses.map((userAddress, index) => (
                    <TableRow key={`address-${index}`}>
                      <TableCell>{userAddress.address ? userAddress.address.address : ''}</TableCell>
                      <Hidden smDown>
                        <TableCell>{userAddress.address ? userAddress.address.baths : 0}</TableCell>
                        <TableCell>{userAddress.address ? userAddress.address.beds : 0}</TableCell>
                        <TableCell>{userAddress.address ? userAddress.address.estimate : 0}</TableCell>
                        <TableCell>{userAddress.address ? userAddress.loanBalance : 0}</TableCell>
                        <TableCell>{moment(userAddress.createdDate).format('MM-DD-YYYY HH:MM')}</TableCell>
                      </Hidden>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          {/*     <Grid container>
            <Grid item xs={12} md={6}>
              <div style={styles.pageTitleSub}>Preferred Rate</div>
              <div style={styles.form}>
                <div style={styles.input}>
                  <TextInput
                    title="Preferred Rate"
                    type="string"
                    placeholder=""
                    result={this.state.rate}
                    onChange={this.handleRateChange}
                  />
                </div>
              </div>
            </Grid>
          </Grid>*/}

          {/*<div style={styles.footerContainer}>
            <Grid container>
              <Grid item xs={6}>
                <div style={{ width: 200 }}>
                  <ActionButton
                    name="Cancel"
                    onClick={this.handleUpdateProfile}
                    fullWidth
                    size="sm"
                    type="secondary"
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div style={{ width: 200 }}>
                    <ActionButton
                      name="Save"
                      onClick={this.handleUpdateProfile}
                      fullWidth
                      size="sm"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>*/}
        </ProfileContainer>
      </AppContainer>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth.auth,
    session: state.session.session
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    { updateSession, openSnackbar, closeSnackbar, addAuth },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter<any, any>(ProfilePage));
